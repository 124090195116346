<template>
    <div>
        <div class="music-t">
            <music />
        </div>
        <p class="title-pici">运输批次编号: {{infoDta.batch_number}}--{{infoDta.carNumberPlate}}</p>
         <van-field
         v-if="infoDta.purchaseType == 4"
            readonly
            clickable
            label-width="120"
            name="picker"
            v-model="purchaseName"
            label="采购类型："
            placeholder="点击选择采购类型"
            @click="showPicker = true"
            />
        <van-popup v-model="showPicker" position="bottom"  :close-on-click-overlay="false">
        <van-picker
            show-toolbar
            :columns="columns"
            value-key="name"
            @confirm="onConfirm"
            @cancel="showPicker = false"
        />
        </van-popup>
    <van-field
        v-if="purchaseTypeId != 1 && infoDta.purchaseType == 4"
        readonly
        clickable
        label-width="120"
        name="picker"
        v-model="ChanneNmae"
        label="选择采购渠道："
        placeholder="选择采购渠道"
        @click="show = true"
        >
            <template #button>
            <van-button size="small" @click.stop="addChangeHan" type="info">新增渠道</van-button>
        </template>
        </van-field>
        <van-popup v-model="show" position="bottom"  :close-on-click-overlay="false">
        <van-picker
            show-toolbar
            :columns="ChanneList"
            value-key="shopName"
            @confirm="onConfirm1"
            @cancel="show = false"
        />
        </van-popup>
        <br>
        <ul >
            <li v-for="(item,index) in infoList" :key="index" class="list-li">
            <!-- <li v-for="(item,index) in 2" :key="index" class="list-li"> -->
                <van-row gutter="20">
                    <van-col span="7">
                        <van-image v-show="item.path" width="100" height="100" :src="item.path" @click="bigImage(item.path,index)" />    
                        <van-image v-show="item.vin_photos" width="100" height="100" :src="item.vin_photos" @click="bigImage(item.vin_photos,index)" />  
                    </van-col>
                    <van-col span="17">
                        <van-field :readonly="isIdx" label-width="70" v-model="item.carNumberPlate" label="车牌号：" placeholder="请输入车牌号" /> 
                        <van-field :readonly="isIdx" label-width="70" v-model="item.vin" label="Vin码：" :placeholder="isIdx?'':'请输入Vin码'" /> 
                        <van-field :readonly="isIdx" label-width="100" v-model="item.defined_num" label="自定义编号：" placeholder="请输入自定义编号" />
                        <div v-if="infoDta.purchaseType ==3">
                            <van-field readonly clickable  name="picker"  v-model="item.simply_scrapiron_Name" label="废铁车分类：" placeholder="选择废铁车类型" @click="ChangeCarShow(index)" ></van-field>
                            <van-popup v-model="CarShow" position="bottom"  :close-on-click-overlay="false">
                                <van-picker
                                    show-toolbar
                                    :columns="CarList"
                                    value-key="name"
                                    @confirm="onCarShow"
                                    @cancel="CarShow = false"
                                />
                            </van-popup>

                            <van-field readonly clickable  name="picker"  label="入库类型：" v-model="item.type_name" placeholder="选择入库类型" @click="ChangeTypeShow(index)" ></van-field>
                            <van-popup v-model="TypeShow" position="bottom"  :close-on-click-overlay="false">
                                <van-picker
                                    show-toolbar
                                    :columns="TypeList"
                                    value-key="name"
                                    @confirm="onTypeShow"
                                    @cancel="TypeShow = false"
                                />
                            </van-popup>
                            <van-field :readonly="isIdx" v-if="item.type==1" label-width="120" v-model="item.weight" label="过磅重量(Kg)：" placeholder="请输入过磅重量" />
                        </div>
                        
                    </van-col>
                </van-row>
                <van-icon v-if="!isIdx" name="clear" class="close" @click="closelist(index)" />
                <van-button v-if="!isIdx" type="primary" @click="update(item,index)">更改</van-button>
                <hr>
            </li>
        </ul>
        <p class="upload-p" v-if="!isIdx">
            <van-button  class="upload-p-btn" type="info" @click="openUp">拍照</van-button>
        </p>
        <div v-if="isIdx">
            <van-button size="large" @click="$emit('closepopup',false)">取消</van-button>
        </div>
        <div class="bb" v-if="!isIdx">
            <van-button @click="$emit('closepopup',false)">取消</van-button>
            <van-button  @click="isshow = true" type="info">确定</van-button>
        </div>
         <van-popup  closeable  v-model="isshow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">温馨提示</p>
              <hr>
              <p style="text-indent: 2em;padding: 10px;"> 提交之后将不可修改！</p>
              <hr>
              <div class="btn-ok">
                  <!-- <van-button @click="show= false">取消</van-button> -->
                  <van-button  @click="isSubmit" type="info">确定</van-button>
              </div>
        </van-popup>
         <van-popup  closeable  v-model="openPopup" class="popup" :close-on-click-overlay="false">
              <h3 style="text-align: center; ">照片上传</h3>
              <div v-if="!isWechat">
                    <van-uploader class="imgUp" v-model="fileList1" :after-read="uploadershang('pai')" :before-delete="afterDelete" :max-count="1" >
                        <van-button size="small" class="upload-p-btn" type="primary">车牌照</van-button>
                    </van-uploader>
                    <van-uploader class="imgUp" v-model="fileList2" :after-read="uploadershang('vin')" :before-delete="afterDelete1" :max-count="1">
                        <van-button size="small" class="upload-p-btn" type="primary" >Vin码照</van-button>
                    </van-uploader>
              </div>
              <div v-else>
                    <div>
                    <p style="text-align: center;">车牌照</p>
                    <img v-if="!imageUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'45Degrees')">
                        <div v-else style="width:100%">
                            <van-image class="vanImage" style="width:100%"  :src="imageUrl" @click="bigImg(imageUrl)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('45Degrees')" />
                            </van-image>
                        </div>
                    </div>
                    <div>
                        <p style="text-align: center;">车架照</p>
                        <img v-if="!VinUrl" style="width:100%" src="../../assets/zhanwu.jpg" alt="" @click="WeChooseImage(1,'Vin')">
                        <div v-else style="width:100%">
                            <van-image class="vanImage" style="width:100%"  :src="VinUrl" @click="bigImg(VinUrl)" >
                                <van-icon class="iconX" name="close" @click.stop="delImgHan('Vin')" />
                            </van-image>
                        </div>
                    </div>
              </div>
            <div class="btn_Up">
                <van-button size="large" v-if="updateID == null" class="upload-p-btn" type="info" @click="submitUp">提交</van-button>
                <van-button size="large" v-if="updateID != null" class="upload-p-btn" type="primary" @click="submitUpdate">更改</van-button>
            </div>
        </van-popup>
        
         <van-popup  :closeable="true"  v-model="voiceShow" class="popup" :close-on-click-overlay="false">
              <p style="text-align: center; ">是否语音播报</p>
              <hr>
              
              <div class="btn-ok">
                  <van-button @click="voiceShow= false">取消</van-button>
                  <van-button type="info" @click="yuying">确定</van-button>
              </div>
        </van-popup>
          <van-popup
            v-model="isAddShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <AddChannel ref="Channels" @addditch="addditch"  />
    </van-popup>
        <audio :src="voice" id="audio"  ref="au"></audio>
    </div>
</template>

<script>
import { Toast } from 'vant';
import { ImagePreview } from "vant";
import { is_post } from "../../Http/api";
import music from '../../components/music.vue'
import AddChannel from './components/AddChannel.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import {  setCookie ,getCookie,deleteCookie} from '@/utils/env'
    export default {
        props:['infoDta'],
        components: {
            music,AddChannel
        },
        data() {
            return {
                fileList1:[],//照片
                fileList2:[],//照片
                isData:{},
                infoList:[],//列表循环
                imageUrl:"",//上传的照片
                isshow:false,
                voiceShow:false,
                isIdx:false,
                voice:'',
                openPopup:false,//拍照弹窗
                CarShow:false,//废铁车类型
                TypeShow:false,//入库类型
                VinUrl:'',//VIN
                vindata:"",//Vin
                updateID:null,
                TypeList:[
                    {id:0,name:'压扁车'},
                    {id:1,name:'精拆车'}
                ],
                CarList:[],
                CarShowIdx:null,
                TypeShowIdx:null,
                wximagelocalids:[],//判断上传张数
                toast:null,
                ChanneList:[],//渠道
                 ChanneNmae:'',//渠道列表
                columns: [
                    // '普通采购','渠道采购'
                    {id:1,name:'普通采购'},
                    {id:2,name:'渠道采购'},
                    {id:3,name:'废铁车采购'},
                ],
                 purchaseName:'普通采购',//采购选择汉子
                showPicker:false,//采购选择弹窗
                show:false,//渠道弹窗
                isAddShow:false,//新增渠道
                purchaseTypeId:1,
                purchasingChannelsId:'',
                // type_id:"",//入库类型ID
                // type_name:"",//入库类型名称
                // simply_scrapiron_id:"",//车辆类型ID
                // simply_scrapiron_Name:"",//车辆类型名称
            }
        },
        watch:{
            isData(n,o){
                let obj = {
                    path:this.imageUrl,
                    carNumberPlate:n.carNumberPlate
                }
            //    this.infoList = [...this.infoList,obj] 
            },
             wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        computed: {
            shopId(){
                return window.localStorage.getItem('shopId') || ''
            }
        },
        mounted () {
           
            this.wcConfig();
             // 判断是否是微信浏览器
            let ua = window.navigator.userAgent.toLowerCase().match(/MicroMessenger/i)
            ua = ua !== null ? ua[0] : null;
            console.log(ua)
            if (ua === "micromessenger") {
                window.addEventListener('pagehide', e => this.beforeunloadHandler(e))
                // window.addEventListener('unload', e => this.unloadHandler(e))
            } else {
                // 监听普通页面离开事件
                window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
                // window.addEventListener('unload', e => this.unloadHandler(e))
            };
        },
        methods: {
             //新增渠道取消
            addditch(e){
                if(e){
                   this.purchasingChannelsId = e.id
                   this.ChanneNmae = e.shopName
                }
                this.isAddShow = false
            },
             //新增渠道
            addChangeHan(){
                this.isAddShow = true
                this.$nextTick(()=>{
                    this.$refs.Channels.getNmae()
                })
            },
              //获取采购渠道
             getPurchasing(){
                this.$http.post('index.php/index/Purchasing_Channels/purchasingChannelsList2').then(res=>{

                    this.ChanneList = res.data.data
                })
            },
             //采购方式选择
            onConfirm(v,i) {
                this.purchaseName = v.name
                this.purchaseTypeId = v.id ;
                if(this.purchaseTypeId != 1) {
                    this.getPurchasing()
                }
                this.showPicker = false;
            },
             //采购渠道选择
            onConfirm1(v){
                this.purchasingChannelsId = v.id
                this.ChanneNmae = v.shopName
                this.show = false;
            },
            beforeunloadHandler(e){
                if(this.infoList.length!=0){
                    let obj = {
                        shopId:this.shopId,
                        id:this.infoDta.id,
                        list:this.infoList
                    }
                    let a = JSON.stringify(obj)
                    setCookie('cache',a,30)
                }
            },
            unloadHandler(e){
                console.log(e)
            },
            //只读不打开
            ChangeCarShow(index){
                if(!this.isIdx){
                    this.CarShow = true
                }
                this.CarShowIdx = index
            },
             //只读不打开
            ChangeTypeShow(index){
                if(!this.isIdx){
                    this.TypeShow = true
                }
                this.TypeShowIdx = index
            },
            //选择车辆种类
            onCarShow(e,i){
                this.infoList[this.CarShowIdx].simply_scrapiron_id = e.id// 入库类型ID
                this.infoList[this.CarShowIdx].simply_scrapiron_Name = e.name//入库类型名称
                this.CarShow = false
            },
            //选择车辆类型
            onTypeShow(e,i){
                this.infoList[this.TypeShowIdx].type = e.id//车辆类型ID
                this.infoList[this.TypeShowIdx].type_name = e.name//车辆类型名称
                this.TypeShow = false
            },
            //照片删除后
            afterDelete(file){
                return new Promise((resolve, reject) => {
                     this.fileList1 = []
                     this.imageUrl = ''
                    resolve();
                });
            },
            //照片上传后
            afterDelete1(file){
                return new Promise((resolve, reject) => {
                    this.fileList2 = []
                     this.VinUrl = ''
                    resolve();
                });
            },
            openUp(){
                if(this.infoList.length!=0){
                    let obj = {
                        shopId:this.shopId,
                        id:this.infoDta.id,
                        list:this.infoList,
                        purchasingChannelsId:this.purchasingChannelsId,
                        purchaseTypeId:this.purchaseTypeId,
                        ChanneNmae:this.ChanneNmae,
                        purchaseName:this.purchaseName,
                    }
                    let a = JSON.stringify(obj)
                    setCookie('cache',a,30)
                }

                this.openPopup = true
                this.updateID = null
                this.fileList1 = []
                this.fileList2 = []
                this.imageUrl = ""
                this.isData ={}
                this.vindata=""
                this.VinUrl = ""
            },
           //提交
            submitUp(){
                if(this.imageUrl && this.VinUrl){
                    this.openPopup = false
                    let obj = {}
                    obj = {
                        path:this.imageUrl,
                        carNumberPlate:this.isData.carNumberPlate,
                        vin_photos:this.VinUrl,
                        vin:this.vindata,
                    }
                    this.infoList.push(obj)
                }else{
                    this.$toast.fail('请上传全部照片！')
                }
            },
            isPlay(n){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                let text = `车牌号：${n.carNumberPlate}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    
                    this.voice = res.data.data
                    toast.clear()//清除加载效果
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            getList(){
                this.$http.post('index.php/scrap_iron/_list').then(res=>{
                    console.log(res)
                    if(res.data.code == 0){
                        this.CarList = res.data.data
                    }else if(res.data.code == -1){
                        this.islogout()
                    }else{
                        this.$toast.fail(res.data.msg)
                    }
                })
            },
            yuying(){
                this.$refs.au.play()
                this.voiceShow = false
            },
             isVin(e){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                
                let text = `车架号：${e}`
                this.$http.post('index.php/wechat/makeYuYin',{text}).then(res=>{
                    this.voice =res.data.data
                    toast.clear()//清除加载效果
                    
                    var u = navigator.userAgent;
                    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
                    if(isiOS){
                        this.voiceShow = true
                    }else{
                        this.$nextTick(()=>{
                            this.$refs.au.play()
                        })
                    }
                })
            },
            kong(e){
                this.fileList1 = []
                this.fileList2 = []
                this.imageUrl = ''
                this.VinUrl = ''
                this.isIdx =false
                this.infoList = []
                this.purchaseTypeId = 1
                this.purchasingChannelsId = ''
                this.ChanneNmae = ''
                this.purchaseName = '普通采购'

                const data = getCookie('cache') ? JSON.parse(getCookie('cache')) : ''
                console.log(data)
                if(data.shopId == this.shopId && this.infoDta.id == data.id){
                    this.infoList = data.list
                    this.purchasingChannelsId = data.purchasingChannelsId
                    this.purchaseTypeId = data.purchaseTypeId
                    this.ChanneNmae = data.ChanneNmae
                    this.purchaseName = data.purchaseName
                }
            },
            //更改
            update(e,i){
                this.openPopup = true
                this.updateID = i
            },
            //更改提交
            submitUpdate(){
                console.log(this.imageUrl && this.VinUrl)
                if(this.imageUrl && this.VinUrl){
                    this.infoList.forEach((item,index)=>{
                            if(index == this.updateID){
                                item.path = this.imageUrl
                                item.carNumberPlate = this.isData.carNumberPlate
                                item.vin_photos = this.VinUrl
                                item.vin = this.vindata
                            }
                            this.openPopup = false
                        
                    })
                }else{
                    this.$toast.fail('请上传全部照片！')
                }
               
            },
            //确定按钮
            async isSubmit(){
                try{
                    let url = this.infoDta.purchaseType !=3 ? 'index.php/batch/saveBatchCarNumberPlate':'index.php/scrap_iron/saveBatchCarNumberPlate'
                     let obj = {
                        dispatch_batch_number:this.infoDta.batch_number,
                        carInfo:this.infoList,
                        purchaseType:this.purchaseTypeId,
                        purchasingChannelsId:this.purchasingChannelsId,
                    }
                    let data = encipherMent( JSON.stringify(obj))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post(url,param).then(res=>res)
                    deleteCookie('cache')
                    this.$toast.success(res.msg)
                    this.$router.push('/Quality')
                }
                catch{}
               
            },
            async setData(id){
                var toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                try{
                    let data = encipherMent( JSON.stringify({dispatch_batch_number:id}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                     let url = this.infoDta.purchaseType !=3 ? 'index.php/batch/batchDetail':'index.php/scrap_iron/batchDetail'
                    const res = await is_post(url,param).then(res=>res)
                    this.isIdx = true
                    toast.clear()//清除加载效果
                    let arr = []
                    res.data.forEach(i => {
                        let img = i.vehicleImages.split(',')
                        let obj = {
                            path:img[0],
                            carNumberPlate:i.carNumberPlate,
                            vin:i.vin,
                            vin_photos:i.vin_photos,
                            defined_num:i.defined_num,
                            type:i.type,
                            simply_scrapiron_id:i.simply_scrapiron_id,
                            simply_scrapiron_Name:i.SimplyScrapironText,
                            type_name:i.type==0?'压扁车':'精拆车',
                            weight:i.weight
                        }
                        arr.push(obj)
                    });
                    this.infoList = arr
                }
                catch{
                    toast.clear()//清除加载效果
                }
                
            },
            //删除弄一项
            closelist(i){
                this.infoList.splice(i,1)
            },
            //查看大图
            bigImage(val,i){
                let image = [val]
                 ImagePreview({
                    images: image,
                    showIndex: true,
                    loop: false,
                    startPosition: i
                });
            },
            //上传照片
            uploadershang(i){
                return file=>{
                    var toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "加载中..."
                    });
                    // file.status = "uploading";
                    // file.message = "上传中...";
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                    this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                        toast.clear()//清除加载效果
                    if (res.data.code == 0) {
                        if(i=='pai'){

                            let url = 'index.php/vehicles_upload/vehicleCarNumberPlate'
                            this.imageUrl = res.data.data
                            this.recognition(url,{path:res.data.data})
                        }else if(i=='vin'){
                            let url = '/index.php/index/bid_management.bid_order/apiBaiDu'
                            this.VinUrl = res.data.data
                            this.recognition(url,{url:res.data.data},'vin')

                        }
                        file.status = "done";
                    } else {
                        if(i=='pai'){
                            this.imageUrl = ''
                            this.fileList1=[]//行驶证正面照片
                        }else if(i=='vin'){
                            this.fileList2=[]//行驶证正面照片
                            this.VinUrl = 'res.data.data'
                        }
                        // file.status = "failed";
                        toast.clear()//清除加载效果
                        // file.message = "上传失败";
                    }
                    }).catch(()=>{
                        this.imageUrl = ''
                         this.fileList1=[],//行驶证正面照片
                        toast.clear()//清除加载效果
                        this.$toast.fail('网络开小差了，请稍后重试！')
                        // file.status = "failed";
                        // file.message = "上传失败";
                    })
                }
           },
        },
        beforeDestroy(){
            console.log(123)
        },
        destroyed () {
            console.log(123);
        },
    }
</script>

<style lang="scss" scoped>
.title-pici{
    padding: 10px;
}
.upload-p{
   .upload-p-btn{
       margin-left: 10px;
   }
}
.list-li{
    padding: 10px;
    position: relative;
   
    .close{
        position: absolute;
        top: 0%;
        right: 3%;
        font-size: 23px;
    }
}
.bb{
    text-align: center;
    .van-button{
        width: 40%;
        margin:0 10px;
    }
}
.btn-ok{
    width: 100%;
    display: flex;
    .van-button{
        flex: 1;
    }
}
 .popup{
    width: 80%;
    padding: 20px;
    text-align: center;
}
.imgUp{
    margin: 0 10px;
}
.btn_Up{
    margin-top: 10px;
}
</style>