<template>
    <div class="warper">
        <div class="isheader">
             <span class="is-span-header go" @click="$router.push('/Quality')"></span>
             <span class="is-span-header">运送批次列表</span>
             <span class="is-span-header" @click="islogout">退出</span>
        </div>
        <div class="content">
            <p>选择运输批次：</p>
            <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            
            >
            <ul class="content-ul">
                <li class="content-ul-li" v-for="item in listBatch" :key="item.id" >
                    <span>
                        运输批次编号: {{item.batch_number}}--{{item.carNumberPlate}}
                    </span>
                     <van-icon v-if="item.status == 1 && idx==2 " class="content-ul-li-icon" name="arrow" @click.stop="goRecognition(item)" />
                     <van-icon v-if="idx!=2 " class="content-ul-li-icon" name="arrow" @click.stop="goRecognition(item)" />
                    <p style="width:100%">创建时间：{{item.createTime}}</p>
                    <van-tag type="primary" style="margin-right:5px" v-if="item.purchaseType == 3 " size="large">废铁车</van-tag>
                    <van-tag type="danger" v-if="item.status > 0 &&idx!=2" size="large">已拍照</van-tag>
                    <van-tag type="danger" v-if="item.status > 1 &&idx==2" size="large">已质检</van-tag>
                    <!-- <span class="status_top" >已拍照</span>
                    <span class="status_top" >已质检</span> -->
                </li>
            </ul>
            </van-list>
        </div>
          <van-popup
            v-model="isDiscernShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <Recognition ref="Recogn" @closepopup="closepopup" :infoDta="infoDta" v-if="QualityId==1" />
            <QualityOk  v-if="QualityId == 2" ref="Quality" :infoDta="infoDta" @closepopup="closepopup" />
    </van-popup>
    </div>
</template>

<script>
import { is_post } from "../../Http/api";
import Recognition from './Recognition.vue'
import QualityOk from './QualityOk'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
    export default {
        props:['idx'],
        components: {
            Recognition,QualityOk
        },
        data() {
            return {
                listBatch:[],
                loading: false,
                finished: false,
                isDiscernShow:false,
                page:1,//页
                pageSize:10,//每页大小
                infoDta:{},//传递的数据
                QualityId:null
            }
        },
        mounted () {
                this.BatchList()
        },
       
        methods: {
            //获取列表
            async BatchList() {
                try{
                    
                    let data = encipherMent( JSON.stringify({pageSize:this.pageSize}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    param.append("page",this.page);
                    const res = await is_post('index.php/batch/listBatch',param).then(res=>res)
                    console.log(res)
                    if( res.data.data.length === 0 ){
                        this.finished = true
                    }else{
                        this.finished = false
                        this.listBatch = res.data.data
                    }
                }
                catch{}
               
            },
            closepopup(){
                this.isDiscernShow = false
            },
          
            //选中
            goRecognition(i){
                this.infoDta = i
                console.log(i)
                this.isDiscernShow = true

                if(this.$route.path ==='/TransportationBatch' ){
                    this.QualityId = 1
                    this.$nextTick(()=>{
                        this.$refs.Recogn.kong(i)
                        this.$refs.Recogn.getList()
                        if(i.status>0){
                            this.$refs.Recogn.setData(i.batch_number)
                        }
                    })
                }else if(this.$route.path ==='/QualityInspectionConfirmed'){
                   this.QualityId = 2
                   this.$nextTick(()=>{
                       this.$refs.Quality.kk()
                       this.$refs.Quality.get(i)
                   })
                }
               
            },
            //下拉刷新
           onLoad(){
                 try{
                     let this_ = this
                     setTimeout(() => {
                        let page = this_.page + 1
                        this_.page = page
                        let pageSize = this_.pageSize
                         let data = encipherMent( JSON.stringify({pageSize:pageSize}))
                            let param = new URLSearchParams();
                            param.append("value",data);
                            param.append("page",this_.page);
                        is_post('index.php/batch/listBatch',param).then(res=>{
                            console.log(res)
                            let arr = res.data.data
                            arr.forEach(i => {
                                this_.listBatch.push(i)
                            });
                            if(this_.listBatch.length >= res.data.total){
                                    this_.finished = true;
                                }else{
                                    this_.finished = false;
                                }
                                this_.loading = false;
                            })
                        
                     },1000)
                   
                    
                }
                catch{}
            }
        },
    }
</script>

<style lang="scss" scoped>
.warper{
    .content{
        padding: 10px;
        margin-top: 50px;
    }
    .van-cell{
        height: 2rem;
    }
    .content-ul-li{
        padding: 10px;
        border-bottom: 1px solid #000;
        position: relative;
        .content-ul-li-icon{
            position: absolute;
            top: 40%;
            right: 3%;
        }
        // .status_top{
        //     position: absolute;
        //     top: 0;
        //     right: 2%;
        //     color: red;
        // }
    }
}
</style>